.header {
  font-weight: 600;
  color: #17356b;
  line-height: 2rem;
}

.divider {
  background-color: #5f8bfe;
  height: 1px;
  width: 100%;
}

.link-primary {
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 20px;
  text-decoration-line: underline;

  color: #5f8bfe;
}

.gap-sm {
  margin-bottom: 1rem; /* Adjust the gap size to your preference */
}

.margin-top-sm {
  margin-top: 1rem; /* Adjust the margin size to your preference */
}

@media (min-width: 576px) {
  .gap-sm {
    margin-bottom: 0;
  }

  .margin-top-sm {
    margin-top: 0;
  }
}
